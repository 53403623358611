import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import textButton from "components/web/textButton";

export default (props) => {
  const viewMode = props?.viewMode;

  const [expandedCard, setExpandedCard] = useState(null);

  const handleToggle = (index) => {
    setExpandedCard((prevIndex) => (prevIndex === index ? null : index));
  };

  return {
    component: "container",
    style: {
      width: "auto",
      height: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: 32,
    },
    children: [
      // Informações textuais
      {
        component: "container",
        style: {
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: Theme.sizes.size_12,
        },
        children: [
          {
            component: "text",
            text: "DEV PARA DEV",
            style: {
              color: Theme.colors.primary.extra_ligth,
              fontSize:
                viewMode === "desktop"
                  ? Theme.sizes.size_48
                  : Theme.sizes.size_28,
              fontFamily: Theme.fontFamilies.primary,
              transition: "color 0.3s ease, transform 0.3s ease",
            },
          },
          {
            component: "container",
            style: {
              display: "flex",
              flexDirection: viewMode === "desktop" ? "row" : "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
            },
            children: [
              {
                component: "text",
                text: "Inovamos nosso ambiente de desenvolvimento",
                style: {
                  color: Theme.colors.primary.extra_ligth,
                  fontSize: Theme.sizes.size_18,
                  fontFamily: Theme.fontFamilies.regular,
                  textAlign: "center",
                  padding: viewMode === "desktop" ? 0 : Theme.sizes.size_4,
                  transition: "color 0.3s ease, transform 0.3s ease",
                },
              },
              {
                component: "text",
                text: "pensando no dev.",
                style: {
                  color: Theme.colors.secondary.medium,
                  fontSize: Theme.sizes.size_18,
                  fontFamily: Theme.fontFamilies.regular,
                },
              },
            ],
          },
        ],
      },
      {
        component: "container",
        style: {
          width: viewMode === "desktop" ? 512 : 382,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 4,
        },
        children: props?.item?.map((menu, index) => {
          const isExpanded = expandedCard === index;
          return {
            render: (
              <Fade left>
                {Render({
                  component: "container",
                  style: {
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                    height: "auto",
                    borderRadius: 4,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    overflow: "hidden",
                    transition: "all 0.5s ease-in-out",
                    padding: isExpanded ? 0 : 4,
                  },
                  children: [
                    textButton({
                      viewMode: viewMode,
                      title: menu?.title,
                      style: {
                        titleBox: {
                          width: "100%",
                          height: "100%",
                          zIndex: 999,
                          padding: 14,
                          borderRadius: 4,
                        },
                        title: {
                          fontSize: 22,
                        },
                        backgroundColorHover: Theme.colors.secondary.regular,
                        backgroundColorDefault: Theme.colors.primary.regular,
                        titleColorHover: Theme.colors.primary.extra_dark,
                        titleColorDefault: Theme.colors.secondary.regular,
                      },
                      onClick: () => {
                        handleToggle(index);
                      },
                    }),
                    {
                      component: "container",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        width: "98%",
                        position: "relative",
                        top: -12,
                        maxHeight: isExpanded ? 422 : 0,
                        overflow: "hidden",
                        alignItems: "center",
                        justifyContent: "center",
                        transition: "all 0.5s ease-in-out",
                        opacity: isExpanded ? 1 : 0,
                        borderRadius: 4,
                        paddingTop: 4,
                      },
                      children: menu.children?.map((subMenu) => ({
                        component: "text",
                        text: subMenu?.description,
                        style: {
                          width: "auto",
                          lineHeight: 1.2,
                          fontSize: 18,
                          textAlign: "justify",
                          wordSpacing: "0.01em",
                          backgroundColor: Theme.colors.secondary.extra_dark,
                          fontFamily: Theme.fontFamilies.regular,
                          color: "white",
                          padding: 14,
                          transition: "opacity 0.5s ease-in-out",
                        },
                      })),
                    },
                  ],
                })}
              </Fade>
            ),
          };
        }),
      },
    ],
  };
};
